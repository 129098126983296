import React, {Component} from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

import CloseCross from './CloseCross';
import LightboxImage from './LightboxImage';
import ArtInfo from './ArtInfo';
import LightLinks from './LightLinks';

class OuterLightbox extends Component {
  handleClickOutside(event) {
    console.log('Called handleClickOutside');
    this.props.handleCloseBox();
  }

  render() {
    return(
      <div className="outer">
        <CloseCross handleCloseBox={this.props.handleCloseBox} />
        <LightboxImage
          altText={this.props.art.altText}
          fileName={this.props.art.fileName}
          medium={this.props.art.medium}
          title={this.props.art.title}
        />
        <ArtInfo origAnchor={this.props.art.origAnchor} printAnchor={this.props.art.printAnchor} optionalText={this.props.art.optionalText}/>
        <LightLinks handleMoveNext={this.props.handleMoveNext} handleMovePrev={this.props.handleMovePrev}/>
      </div>
    );
  }
}

OuterLightbox.propTypes = {
  art: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,
    origAnchor: PropTypes.string,
    printAnchor: PropTypes.string,
    medium: PropTypes.string,
    optionalText: PropTypes.string,
  }).isRequired,
  handleMoveNext: PropTypes.func.isRequired,
  handleMovePrev: PropTypes.func.isRequired,
  handleCloseBox: PropTypes.func.isRequired,
};

export default onClickOutside(OuterLightbox);