import React, {Component} from 'react';
import PropTypes from 'prop-types';

class LightboxImage extends Component {
  render() {
    return (
      <div className="inner">
        <img src={`https://media.niamhwitch.com/images/artwork/${this.props.fileName}_01.jpg`} alt={this.props.altText} />
        <p className="matter">{this.props.title && `"${this.props.title}"`}<br />{this.props.medium}</p>
      </div>
    );
  }
}

LightboxImage.propTypes = {
  fileName: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  title: PropTypes.string,
  medium: PropTypes.string,
};

export default LightboxImage;