import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Exhibit extends Component {
  render() {
    return (
      <div className="thumbs col-lg-3 col-md-3">
        <img
          src={`https://media.niamhwitch.com/images/artwork/${this.props.art.fileName}_02.jpg`}
          alt={this.props.art.altText}
          onClick={() => this.props.onClick(this.props.id)}
        />
      </div>
    );
  }
}

export default Exhibit;

Exhibit.propTypes = {
  art: PropTypes.shape({
    fileName: PropTypes.string,
    altText: PropTypes.string,
    origAnchor: PropTypes.string,
    printAnchor: PropTypes.string,
    medium: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};
