import React, { Component } from 'react';
import Navigation from '../Navigation/Navigation';
import BackgroundPageHeader from '../BackgroundPageHeader/BackgroundPageHeader';
import PageContent from '../PageContent/PageContent';
import Gallery from '../Gallery/Gallery';
import Footer from '../Footer/Footer';

class App extends Component {
  render() {
    return (
      <div>
        <Navigation />
        <BackgroundPageHeader />
        <PageContent />
        <Gallery />
        <Footer />
      </div>
    );
  }
}

export default App;
