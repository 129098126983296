import React, {Component} from 'react';
import PropTypes from 'prop-types';

class LightLinks extends Component {
  render() {
    return(
      <div className="lightlinkcontainer">
        <div onClick={this.props.handleMoveNext} className="next lightlink">Next</div>
        <div onClick={this.props.handleMovePrev} className="prev lightlink">Previous</div>
      </div>
    );
  }
}

LightLinks.propTypes = {
  handleMoveNext: PropTypes.func.isRequired,
  handleMovePrev: PropTypes.func.isRequired,
};

export default LightLinks;