import React, { Component } from 'react';

class BackgroundPageHeader extends Component {
  render() {
    return (
      <header className="business-header" id="backgroundpageheader">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="tagline">
                NiamhWitch - Art * Spirit * Mind * Life
              </h1>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default BackgroundPageHeader;
