import React, {Component} from 'react';
import PropTypes from 'prop-types';

class CloseCross extends Component {
  render() {
    return(
      <div onClick={this.props.handleCloseBox} className="cross">X</div>
    );
  }
}

CloseCross.propTypes = {
  handleCloseBox: PropTypes.func.isRequired,
};

export default CloseCross;
