import React, { Component } from 'react';

class PageContent extends Component {
  render() {
    return (
      <div className="container" id="pagecontent">
        <hr />
        <div className="row">
          <div className="col-sm-8">
            <h2 id="about">
              <font color="662d91">Welcome!</font>
            </h2>
            <p>
              My name is Niamh and I like to create! NiamhWitch is kind of an
              umbrella business that incorporates many of my interests and
              creations.
            </p>
            <p>
              <b>"Art by Niamh"</b> (with its accompanying YouTube channel:{' '}
              <a
                href="https://www.youtube.com/channel/UC_Aqq-Nf18FL99akIliVv6w"
                target="_blank"
                rel="noopener noreferrer"
              >
                Niamh-Creates
              </a>
              ) is focused on fine art, adult colouring, and other crafts.
              <br />
              <b>"The Rainbow Cauldron"</b> (
              <a
                href="https://www.youtube.com/channel/UCCv2z_Es5tefWiiLpKBc_vA"
                target="_blank"
                rel="noopener noreferrer"
              >
                YouTube
              </a>
              ) is the spiritual side of my business and offers spiritual
              supplies and videos.
              <br />
              <b>"Niamh-Learns"</b> (
              <a
                href="https://www.youtube.com/channel/UCouAZ6w6OFy-VI3CWb0X05g"
                target="_blank"
                rel="noopener noreferrer"
              >
                YouTube
              </a>
              ) is kind of my miscellaneous channel where I post
              language-learning and gaming videos.
              <br />
              Finally,
              <b> "NivyNaps"</b> is the business that started it all! Through
              NivyNaps, I offer eco-friendly cloth items like cloth diapers,
              wipes, unpaper towels, mama cloth and more!
            </p>
            <h3>
              <font color="1d4831">Social Media</font>
            </h3>
            <p>
              <a
                href="https://www.facebook.com/RainbowCauldron"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://media.niamhwitch.com/images/facebook-icon.png" alt="Facebook Icon" />
              </a>
              <a
                href="https://www.instagram.com/niamh.dhabolt/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://media.niamhwitch.com/images/instagram-icon.png" alt="Instagram Icon" />
              </a>
              <a
                href="https://www.pinterest.com/niamhdhabolt/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://media.niamhwitch.com/images/pinterest-icon.png" alt="Pinterest Icon" />
              </a>
            </p>
            <h3>
              <font color="1d4831">Shops</font>
            </h3>
            <p>
              <a
                href="https://www.etsy.com/shop/NivyNaps"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://media.niamhwitch.com/images/etsy-icon.png" alt="Etsy Icon" />
              </a>
              <a
                href="https://www.redbubble.com/people/niamhwitch"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://media.niamhwitch.com/images/redbubble-icon.png" alt="RedBubble Icon" />
              </a>
            </p>
            <p>
              <iframe
                src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=26&l=ur1&category=artscrafts&banner=1ND6N5RQP0FN5H8WRW82&f=ifr&linkID=ca4185426d9061966f7f8fd2810d55d2&t=niamhwitch0c-20&tracking_id=niamhwitch0c-20"
                width="468"
                height="60"
                scrolling="no"
                border="0"
                marginWidth="0"
                style={{ border: 'none' }}
                frameBorder="0"
                title="amazon-adsystem"
              />
            </p>
          </div>
          <div className="col-sm-4">
            <img src="https://media.niamhwitch.com/images/self-01.jpg" alt="Portrait of Niamh" />
            <h2>
              <font color="1d4831">Contact</font>
            </h2>
            <address>
              <strong>Niamh Dhabolt</strong>
              <br />
              <abbr title="Email">E:</abbr>
              <a id="email">niamhwitch@gmail.com</a>
              <br />
            </address>
            <p>
              <a
                href="https://www.patreon.com/niamhwitch"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://media.niamhwitch.com/images/become_a_patron_button.png"
                  alt="Patreon Subscribe"
                />
              </a>
            </p>
            <p>
              Are you an Amazon shopper? If so, consider using my Amazon
              Affiliate link when you make your next purchase! A portion of
              Amazon's profits will go toward my shop, without any added costs
              to you!
            </p>
            <p>
              <a
                href="https://smile.amazon.com/?tag=niamhwitch0c-20"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://media.niamhwitch.com/images/amazon-icon.png" alt="Amazon Smile" />
              </a>
            </p>
          </div>
        </div>
        <hr />

        <div className="row">
          <div className="col-sm-3">
            <img
              className="img-square img-responsive img-center"
              src="https://media.niamhwitch.com/images/art-avy01.png"
              alt=""
            />
            <br />
            <center>
              <a
                href="https://www.youtube.com/channel/UC_Aqq-Nf18FL99akIliVv6w"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://media.niamhwitch.com/images/youtube-icon2.png" alt="YouTube Icon" />
              </a>
              <a
                href="https://www.etsy.com/shop/NivyNaps"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://media.niamhwitch.com/images/etsy-icon2.png" alt="Etsy Icon" />
              </a>
              <a
                href="https://www.redbubble.com/people/niamhwitch"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://media.niamhwitch.com/images/redbubble-icon2.png" alt="RedBubble Icon" />
              </a>
            </center>
            <h2>
              <font color="1d4831">Art by Niamh</font>
            </h2>
            <br />
            Original artwork, adult colouring, crafts, & more!
            <br />
            <b>
              <a
                href="https://www.etsy.com/shop/NivyNaps"
                target="_blank"
                rel="noopener noreferrer"
              >
                Shop Originals
              </a>{' '}
              *
              <a
                href="https://www.redbubble.com/people/niamhwitch"
                target="_blank"
                rel="noopener noreferrer"
              >
                Shop Prints
              </a>{' '}
              *<a href="#gallery-banner">Gallery</a>
            </b>
          </div>
          <div className="col-sm-3">
            <img
              className="img-square img-responsive img-center"
              src="https://media.niamhwitch.com/images/spirit-avy01.png"
              alt="Spirit Icon"
            />
            <br />
            <center>
              <a
                href="https://www.youtube.com/channel/UCCv2z_Es5tefWiiLpKBc_vA"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://media.niamhwitch.com/images/youtube-icon2.png" alt="YouTube Icon" />
              </a>
              <a
                href="https://www.etsy.com/shop/NivyNaps"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://media.niamhwitch.com/images/etsy-icon2.png" alt="Etsy Icon" />
              </a>
            </center>
            <h2>
              <font color="1d4831">The Rainbow Cauldron</font>
            </h2>
            <p>Tarot, altar supplies, videos, & more!</p>
          </div>
          <div className="col-sm-3">
            <img
              className="img-square img-responsive img-center"
              src="https://media.niamhwitch.com/images/mind-avy01.png"
              alt="Mind Icon"
            />
            <br />
            <center>
              <a
                href="https://www.youtube.com/channel/UCouAZ6w6OFy-VI3CWb0X05g"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://media.niamhwitch.com/images/youtube-icon2.png" alt="YouTube Icon" />
              </a>
            </center>
            <h2>
              <font color="1d4831">Niamh-Learns</font>
            </h2>
            <p>Language learning, gaming, & more!</p>
          </div>
          <div className="col-sm-3">
            <img
              className="img-square img-responsive img-center"
              src="https://media.niamhwitch.com/images/life-avy01.png"
              alt="Life Icon"
            />
            <br />
            <center>
              <a
                href="https://www.etsy.com/shop/NivyNaps"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://media.niamhwitch.com/images/etsy-icon2.png" alt="Etsy Icon" />
              </a>
            </center>
            <h2>
              <font color="1d4831">NivyNaps</font>
            </h2>
            <p>Cloth diapers, wipes, unpaper towels, mama cloth, & more!</p>
          </div>
        </div>
        <center>
          <br />
          <h3>
            Interested in a portrait of your pet? Email me at
            niamhwitch@gmail.com for more information!
          </h3>
        </center>
        <hr />
      </div>
    );
  }
}

export default PageContent;
