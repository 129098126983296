import React, { Component } from 'react';
import Papa from 'papaparse';
import KeyHandler, {KEYDOWN} from 'react-key-handler';
import Lightbox from '../Lightbox/Lightbox';
import Exhibit from '../Exhibit/Exhibit';

const ESC_KEY = 'Escape';

class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
      art: null
    };
  }

  componentWillMount() {
    Papa.parse("art-info.csv", {
      header: true,
      download: true,
      complete: (results) => {
        this.setState({
          art: results.data.reverse()
        })
      }
    }
    );
  }

  handleImageClick(num) {
    this.setState({
      photoIndex: num,
      isOpen: true,
    })
  }

  handleKeyPress(event) {
    event.preventDefault();
    this.setState({
      isOpen: false,
    });

  }

  handleCloseBox() {
    this.setState({
      isOpen: false,
    });
  }

  handleMovePrev(event) {
    event.preventDefault();
    this.setState({
      photoIndex: (this.state.photoIndex + this.state.art.length -1) % this.state.art.length
    });
  }

  handleMoveNext(event) {
    event.preventDefault();
    this.setState({
      photoIndex: (this.state.photoIndex + 1) % this.state.art.length
    });
  }

  render() {
    return (
      <div>
        <img
          className="img-responsive img-center"
          src="https://media.niamhwitch.com/images/website-banner-02.jpg"
          id="gallery-banner"
          alt=""/>
        <div className="container-fluid">
          <h2 id="gallery-title" style={{ textAlign: 'center' }}>
            Art Gallery
          </h2>
          <div className="container">
            <div id="gallery" className="row">
              {this.state.art && this.state.art.map((item, num) =>
                (<Exhibit
                  key={num}
                  id={num}
                  art={item}
                  onClick={this.handleImageClick.bind(this)}/>)
              )}
            </div>

            {this.state.isOpen && (
              <KeyHandler keyEventName={KEYDOWN} keyValue={ESC_KEY} onKeyHandle={this.handleKeyPress.bind(this)} />
            )}

            {this.state.isOpen && (
              <Lightbox
                art={this.state.art[this.state.photoIndex]}
                handleMoveNext={this.handleMoveNext.bind(this)}
                handleMovePrev={this.handleMovePrev.bind(this)}
                handleCloseBox={this.handleCloseBox.bind(this)}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Gallery;
