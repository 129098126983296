import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ArtInfo extends Component {
  render() {
    return(
      <div style={{textAlign: 'center'}}>
        {this.props.origAnchor &&
        <a href={this.props.origAnchor} target="_blank" className="printorig" rel="noopener noreferrer">Original</a>
        }
        {
          this.props.origAnchor && this.props.printAnchor && "\u2800\u25C6\u2800"
        }
        {this.props.printAnchor &&
        <a href={this.props.printAnchor} target="_blank" className="printorig" rel="noopener noreferrer">Prints</a>
        }
        {this.props.optionalText &&
          <p className={"optionaltext"}>{this.props.optionalText}</p>
        }
      </div>
    );
  }
}

ArtInfo.propTypes = {
  origAnchor: PropTypes.string,
  printAnchor: PropTypes.string,
  optionalText: PropTypes.string,
};

export default ArtInfo;