import React, {Component} from 'react';
import PropTypes from 'prop-types';

import OuterLightbox from './components/OuterLightbox';

class Lightbox extends Component {

  render() {
    return (
      <div className="lightbox">
        <OuterLightbox
          art={this.props.art}
          handleMoveNext={this.props.handleMoveNext}
          handleMovePrev={this.props.handleMovePrev}
          handleCloseBox={this.props.handleCloseBox}
          handleKeyPress={this.props.handleKeyPress}
        />
      </div>
    );
  }
}

Lightbox.propTypes = {
  art: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,
    origAnchor: PropTypes.string,
    printAnchor: PropTypes.string,
    medium: PropTypes.string,
  }).isRequired,
  handleMoveNext: PropTypes.func.isRequired,
  handleMovePrev: PropTypes.func.isRequired,
  handleCloseBox: PropTypes.func.isRequired,
};

export default Lightbox;