import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <div>
        <div className="container-fluid">
          <footer>
            <div className="row">
              <p className="col-lg-12 text-center">
                Copyright &copy; NiamhWitch {new Date().getFullYear()}
              </p>
            </div>
          </footer>
        </div>
        <img
          src="https://media.niamhwitch.com/images/website-banner-01.jpg"
          className="img-responsive img-center"
          alt=""/>
      </div>
    );
  }
}

export default Footer;
